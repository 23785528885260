import React, { useState, useEffect } from 'react';
import { Rocket, Wallet, Twitter, MessageCircle, Gift, Heart, Share2, MessageSquare, ExternalLink } from 'lucide-react';

const TokenSalesPage = () => {
  const [isHovering, setIsHovering] = useState(false);
  const [currentBackground, setCurrentBackground] = useState(0);

  // Animated background effect
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentBackground((prev) => (prev + 1) % 360);
    }, 50);
    return () => clearInterval(interval);
  }, []);

  const adventureSteps = [
    {
      title: "Surviving the Ice Age",
      description: "A powerful species that survived the harsh conditions of the glacial period.",
      image: "/mamut/winter.jpg"
    },
    {
      title: "Escape from Tribes",
      description: "Breaking free from primitive tribes, gaining freedom in the modern world.",
      image: "/mamut/tribes.jpg"
    },
    {
      title: "Entering Crypto Era",
      description: "Now a new adventure begins: Rising in the crypto ecosystem!",
      image: "/mamut/crypto.jpg"
    }
  ];

  const airdropTasks = [
    {
      icon: <Heart className="text-pink-500" size={24} />,
      task: "Like the Tweet",
      reward: "50",
      status: "Active"
    },
    {
      icon: <Share2 className="text-blue-500" size={24} />,
      task: "Retweet",
      reward: "100",
      status: "Active"
    },
    {
      icon: <MessageSquare className="text-green-500" size={24} />,
      task: "Comment",
      reward: "75",
      status: "Active"
    },
    {
      icon: <Twitter className="text-sky-500" size={24} />,
      task: "Follow @Mammothzs",
      reward: "150",
      status: "Active"
    }
  ];

  return (
    <div className="min-h-screen bg-black text-white relative overflow-hidden">
      {/* Animated Background */}
      <div 
        className="absolute inset-0 opacity-20 transition-all duration-1000"
        style={{
          background: `linear-gradient(${currentBackground}deg, #ffd700 0%, #000000 100%)`,
        }}
      />
      
      <div className="container mx-auto px-4 py-12 relative z-10">
        {/* Header */}
        <div className="flex justify-between items-center mb-12">
          <h1 className="text-5xl font-bold bg-gradient-to-r from-yellow-400 to-yellow-600 bg-clip-text text-transparent">
            MAMUT Token
          </h1>
          <button className="flex items-center gap-2 bg-yellow-500/10 hover:bg-yellow-500/20 px-6 py-3 rounded-xl backdrop-blur-sm transition-all text-yellow-400">
            <Wallet size={20} />
            <span>Connect Wallet</span>
          </button>
        </div>

        {/* Token Info Section */}
        <div className="grid md:grid-cols-2 gap-8 max-w-6xl mx-auto">
          <div className="bg-yellow-500/5 rounded-2xl p-8 backdrop-blur-sm border border-yellow-500/10">
            <img 
              src="/mamut/token.jpg" 
              alt="MAMUT Token"
              className="w-full h-72 object-cover rounded-xl mb-8 shadow-2xl hover:scale-[1.02] transition-transform"
            />
            <div className="space-y-4">
              <div className="flex justify-between items-center">
                <span className="text-gray-400">Total Holders</span>
                <span className="text-yellow-400 font-bold">0</span>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-gray-400">Network</span>
                <span className="text-yellow-400 font-bold">Solana</span>
              </div>
            </div>
          </div>

          {/* Logo and Buy Section */}
          <div className="space-y-6">
            <div className="bg-yellow-500/5 rounded-2xl p-8 backdrop-blur-sm border border-yellow-500/10">
              <img 
                src="/mamut/mamut.png" 
                alt="MAMUT Logo"
                className="w-full max-h-96 object-contain rounded-xl mb-4 hover:scale-[1.02] transition-transform"
              />
            </div>

            <div className="bg-yellow-500/5 rounded-2xl p-8 backdrop-blur-sm border border-yellow-500/10">
              <a
                href="https://pump.fun/coin/DhySZbL8fQNbCxzGi36qLNga8QLbqGdBqmTD1SLFxsos"
                target="_blank"
                rel="noopener noreferrer"
                className="block w-full"
              >
                <button
                  className="w-full bg-gradient-to-r from-yellow-400 to-yellow-600 hover:from-yellow-500 hover:to-yellow-700 text-black py-4 px-8 rounded-xl font-bold text-lg transition-all transform hover:scale-[1.02] flex items-center justify-center gap-2"
                  onMouseEnter={() => setIsHovering(true)}
                  onMouseLeave={() => setIsHovering(false)}
                >
                  <Rocket size={24} className={isHovering ? "animate-bounce" : ""} />
                  {isHovering ? "Go to PumpFun!" : "Buy Token"}
                </button>
              </a>
            </div>
          </div>
        </div>

        {/* Airdrop Section */}
        <div className="mt-20 mb-16">
          <h2 className="text-4xl font-bold text-center mb-12 bg-gradient-to-r from-yellow-400 to-yellow-600 bg-clip-text text-transparent flex items-center justify-center gap-3">
            <Gift className="text-yellow-400" size={32} />
            Airdrop Tasks
          </h2>
          
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6 max-w-6xl mx-auto">
            {airdropTasks.map((task, index) => (
              <div 
                key={index}
                className="bg-yellow-500/5 rounded-xl p-6 backdrop-blur-sm border border-yellow-500/10 hover:scale-[1.02] transition-transform"
              >
                <div className="flex items-center gap-3 mb-4">
                  {task.icon}
                  <h3 className="text-lg font-bold">{task.task}</h3>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-yellow-400 font-bold">{task.reward} MAMUT</span>
                  <span className="text-green-400">{task.status}</span>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Mamut's Journey */}
        <div className="mt-20 mb-16">
          <h2 className="text-4xl font-bold text-center mb-12 bg-gradient-to-r from-yellow-400 to-yellow-600 bg-clip-text text-transparent">
            Mamut's Incredible Journey
          </h2>
          
          <div className="grid md:grid-cols-3 gap-8">
            {adventureSteps.map((step, index) => (
              <div 
                key={index}
                className="bg-yellow-500/5 rounded-2xl p-6 backdrop-blur-sm border border-yellow-500/10 hover:scale-[1.02] transition-transform"
              >
                <img 
                  src={step.image}
                  alt={step.title}
                  className="w-full h-48 object-cover rounded-xl mb-4"
                />
                <h3 className="text-xl font-bold text-yellow-400 mb-2">{step.title}</h3>
                <p className="text-gray-300">{step.description}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Social Links */}
        <div className="flex justify-center gap-6 mt-12">
          <a
            href="https://twitter.com/Mammothzs"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-yellow-500/10 hover:bg-yellow-500/20 p-4 rounded-full transition-all group"
          >
            <Twitter size={24} className="text-yellow-400 group-hover:scale-110 transition-transform" />
          </a>
          <a
            href="https://t.me/your-telegram"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-yellow-500/10 hover:bg-yellow-500/20 p-4 rounded-full transition-all group"
          >
            <MessageCircle size={24} className="text-yellow-400 group-hover:scale-110 transition-transform" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default TokenSalesPage;