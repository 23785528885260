import TokenSalesPage from './components/TokenSalesPage';

function App() {
  return (
    <div>
      <TokenSalesPage />
    </div>
  );
}

export default App;